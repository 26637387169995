<template>
  <a-modal
    id="show-video-modal"
    v-model:visible="showStream"
    :title="deviceName + ' Streaming'"
    :footer="null"
    destroy-on-close
    centered
    width="1000px"
    :mask-closable="false"
    :get-popup-container="() => document.getElementById('stream-modal-body')"
    @cancel="showStream = false"
  >
    <div id="stream-modal-body" class="w-100">
      <VideoStream
        :serialNumber="device.Serial_number"
        :deviceSettings="deviceSettings"
        @closeModal="showStream = false"
      />
    </div>
  </a-modal>

  <a-card
    size="small"
    class="station-card"
    :class="{
      'active-card': isActive,
      'idle-card': isActive && isIdle,
    }"
  >
    <a-card-meta>
      <template #title>
        <div class="title-wrapper mb-1">
          <a-space class="d-flex align-items-center">
            <a-typography-title :level="4" class="mb-1">
              {{
                deviceName.length > 20
                  ? deviceName.slice(0, 20) + '...'
                  : deviceName
              }}
            </a-typography-title>
            <a-rate
              v-if="device.starred"
              :value="1"
              :count="1"
              style="margin-top: -0.4em"
            />
          </a-space>

          <a-popover arrow hover placement="bottom">
            <span
              class="center info-icon"
              style="width: 20px; height: 20px"
              :class="{
                'bg-primary': isActive,
                'bg-secondary': !isActive,
              }"
            >
              <InfoOutlined />
            </span>
            <template #content>
              <div
                v-for="row in deviceHoverInfo"
                :key="row.key"
                class="hoverInfo"
              >
                <span v-if="row.key === 'Organization'">
                  {{ row.title }} :
                  <a-tag color="processing">
                    {{ device[row.key].Org_name || organization }}
                  </a-tag>
                </span>
                <span v-else-if="row.key === 'version_no'">
                  {{ row.title }} :
                  <a-tag color="processing">
                    <!-- getting version_no only for active devices -->
                    {{ deviceSettings?.version_no || '-' }}
                  </a-tag>
                </span>
                <span v-else>
                  {{ row.title }} :
                  <a-tag color="processing">{{ device[row.key] || '-' }}</a-tag>
                </span>
              </div>
            </template>
          </a-popover>
        </div>
      </template>
    </a-card-meta>

    <template #actions v-if="isActive">
      <div class="d-flex justify-content-center">
        <a-button
          type="link"
          :disabled="analyticsTraceDisable"
          style="flex: 1; border: none; box-shadow: none"
          @click="redirectToPage('Analytics')"
        >
          <template #icon>
            <i class="bi bi-box-arrow-up-right mr-2" />
          </template>
          Analytics
        </a-button>

        <a-divider type="vertical" style="height: 30px" />

        <a-button
          type="link"
          :disabled="analyticsTraceDisable"
          style="flex: 1; border: none; box-shadow: none"
          @click="redirectToPage('Trace')"
        >
          <template #icon>
            <i class="bi bi-box-arrow-up-right mr-2" />
          </template>
          Trace
        </a-button>
      </div>
    </template>

    <div class="device-content">
      <div class="mt-1">
        <!-- Device Activity data/tags and stream icon -->
        <div class="tags-wrapper center flex-wrap">
          <a-tooltip title="View Live Stream">
            <i
              v-if="isActive"
              class="bi bi-cast strem-icon"
              @click="showStream = true"
            />
          </a-tooltip>

          <a-tag
            :color="isActive ? 'processing' : 'default'"
            style="font-size: 14px"
            class="d-flex align-items-center"
          >
            <template #icon>
              <global-outlined v-if="isActive" />
              <custom-icon v-else :icon="$customIcons.OfflineInternetIcon" />
            </template>
            {{ isActive ? 'Online' : 'Offline' }}
          </a-tag>

          <span
            v-if="isActive && isIdle"
            class="text-danger inference-activity"
          >
            Idle
          </span>

          <div
            v-if="deviceSettings?.isInferenceRunning"
            class="inference-activity"
          >
            <span :class="{ 'text-danger': !deviceSettings?.recordInference }">
              Trace {{ deviceSettings?.recordInference ? 'On' : 'Off' }}
            </span>
            <span
              :class="{ 'text-secondary': !deviceSettings?.isInferenceRunning }"
            >
              Guidance Mode
            </span>
          </div>

          <div
            v-if="
              deviceSettings?.recordInference &&
              !deviceSettings?.isInferenceRunning
            "
            :class="{ 'text-secondary': !deviceSettings?.recordInference }"
            class="inference-activity"
          >
            Trace {{ deviceSettings?.recordInference ? 'On' : 'Off' }}
          </div>

          <div
            v-if="deviceSettings?.record_shift"
            class="text-success font-weight-bold mt-1"
          >
            Recording
          </div>
        </div>

        <div v-if="isActive && currentTask" class="task-wrapper">
          <label>Current Operation:</label>
          <span class="text-primary">{{ currentTask }}</span>
          <!-- <span v-else class="text-muted">None</span> -->
        </div>
      </div>

      <!-- Device Cycle -->
      <div class="device-cycles-info">
        <div
          v-if="!deviceSettings?.record_shift"
          class="w-100 center flex-column my-2 middle-card"
        >
          <strong>{{ getActivityData('number_of_cycles') }}</strong>
          <span>Cycles Performed (Today)</span>
        </div>

        <div
          v-if="deviceSettings?.record_shift"
          class="w-100 center flex-column my-2 middle-card"
        >
          <strong>{{ getActivityData('hours_recorded') }}</strong>
          <span>Hours Recorded</span>
        </div>

        <a-row
          v-if="!deviceSettings?.record_shift"
          :gutter="8"
          class="w-100 mt-2 d-flex align-items-start"
        >
          <a-col
            v-for="(card, i) in smallCardConfig"
            :span="(i + 1) % 2 === 0 ? 12 : 6"
            class="center flex-column small-card"
          >
            <strong>{{ getActivityData(card.key) }}</strong>
            <span class="text-center">{{ card.title }} </span>
          </a-col>
        </a-row>

        <!-- <label
          >Cycles Count <small class="font-weight-bold">(last 24hrs)</small>
        </label>
        <ul class="cycle-list count-list">
          <li>
            <label>Total Cycles:</label>
            <span>{{ getActivityData('number_of_cycles') }}</span>
          </li>
          <li>
            <label>Good Cycles:</label>
            <span>{{ getActivityData('good_cycles') }}</span>
          </li>
          <li>
            <label>Bad Cycles:</label>
            <span>{{ getActivityData('bad_cycles') }}</span>
          </li>
        </ul> -->

        <!-- <label
          >Cycles Time
          <small class="font-weight-bold">(last 24hrs)</small></label
        >
        <ul class="cycle-list time-list">
          <li>
            <label>Average Cycles Time:</label>
            <span>{{ getActivityData('average_cycle_time') }}</span>
          </li>
          <li>
            <label>Total Cycles Time:</label>
            <span>{{ getActivityData('total_cycles_time') }}</span>
          </li>
        </ul> -->
      </div>
    </div>
  </a-card>
</template>
<script>
import { mapGetters } from 'vuex';
import { GlobalOutlined, InfoOutlined } from '@ant-design/icons-vue';
import { dateTimeFormat } from 'src/config/date-format-config.js';
import deviceHoverInfo from '@/config/device-hover-info-config.js';
import VideoStream from './VideoStream.vue';
import dayjs from 'dayjs';

export default {
  components: {
    VideoStream,
    InfoOutlined,
    GlobalOutlined,
  },
  props: {
    device: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    activeDevices: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    activeDevicesSettings: {
      type: Array,
      required: true,
      default: () => [],
    },

    idToTaskNameMapping: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    idToDeviceSerialNoMapping: {
      type: Object,
      required: true,
      default: () => ({}),
    },

    activeDevicesStatus: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup() {
    return {
      deviceHoverInfo,
      smallCardConfig: [
        { key: 'bad_cycles', title: 'Bad cycles' },
        { key: 'average_value_add_percentage', title: 'Value add percentage' },
        { key: 'takt_grade', title: 'Takt grade' },
      ],
    };
  },
  data() {
    return {
      deviceSettings: null,
      showStream: false,
      deviceActivity: {
        isInferenceRunning: false,
        recordInference: false, //trace,
        record_shift: false,
      },
    };
  },

  computed: {
    ...mapGetters(['organization']),
    deviceName() {
      return this.device.display_name || this.device.Serial_number;
    },
    currentTask() {
      if (!this.deviceSettings) return '';
      return this.idToTaskNameMapping[this.deviceSettings?.Task];
    },
    isActive() {
      return this.activeDevices[this.device.Serial_number]?.active;
    },
    isIdle() {
      if (!this.deviceSettings || !Object.keys(this.deviceSettings)?.length)
        return false;
      return Object.keys(this.deviceActivity).every(
        (activity) => this.deviceSettings[activity] === false
      );
    },
    analyticsTraceDisable() {
      const cyclesPerformed = this.getActivityData('number_of_cycles');
      return !this.isActive || !cyclesPerformed || cyclesPerformed === '-';
    },
  },

  watch: {
    activeDevices(dict) {
      if (!dict || !Object.keys(dict).length) return;
      this.deviceSettings = this.activeDevices[this.device.Serial_number];
    },
    // activeDevicesSettings(value) {
    //   if (!Object.keys(value).length) return;
    //   this.deviceSettings = value.find(
    //     ({ Device: deviceId }) => deviceId === this.device.Device
    //   );
    // }
  },

  methods: {
    getActivityData(attributeName) {
      if (!Object.keys(this.activeDevicesStatus).length) return '-';
      const deviceSno = this.device.Serial_number;
      const task = this.device.Task;
      const deviceStatus = this.activeDevicesStatus?.[`${deviceSno}${task}`];

      if (!this.isActive || !deviceStatus) return '-';
      if (
        deviceStatus &&
        attributeName === 'takt_grade' &&
        deviceStatus['takt_percentage']
      ) {
        const takt_percent = deviceStatus['takt_percentage'].split('.')[0];
        return Number(takt_percent) > 0 ? deviceStatus['takt_grade'] : '-';
      } else if (deviceStatus && deviceStatus[attributeName]) {
        return deviceStatus[attributeName] === '0%'
          ? '-'
          : deviceStatus[attributeName];
      } else return '-';
    },

    getDateTime(date) {
      return `${dayjs(date).format(dateTimeFormat)}`;
    },

    redirectToPage(name) {
      // const d1 = dayjs(new Date().toISOString());
      // const d2 = d1.subtract(24, 'hour');

      const d1 = dayjs().startOf('day');
      const d2 = dayjs().endOf('day');

      const query = {
        taskId: this.deviceSettings?.Task,
        deviceId: JSON.stringify([this.device.Serial_number]),
        startTime: this.getDateTime(d1),
        endTime: this.getDateTime(d2),
      };

      let { href } = this.$router.resolve({ name, query });
      window.open(href, '_blank');
    },
  },
};
</script>

<style>
.station-card {
  margin-bottom: 24px;
  width: 100%;
  min-height: 280px;
  margin-top: 6px;
  background: #fff;
  /* box-shadow: rgba(149, 157, 165, 0.3) 0px 0px 24px 12px; */
  box-shadow: 2px 4px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  border-radius: 10px;
  box-sizing: border-box;
}

.station-card .ant-card-body {
  padding: 16px 20px;
  box-sizing: border-box;
  height: 100%;
}

.station-card .ant-card-actions {
  border-radius: 0px 0px 10px 10px;
}
/* 
.station-card .ant-card-actions > li {
  margin: 0px;
} */

.station-card .ant-card-actions > li,
.station-card .ant-card-actions > li span:first-child {
  height: 100%;
}

/* .active-card {
  box-shadow: inset 0 0 6px 1px #008000;
}

.idle-card {
  box-shadow: inset 0 0 6px 1px rgb(255, 0, 0);
} */

.active-card .ant-card-body {
  padding: 16px 20px !important;
  height: 100%;
}

.device-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tags-wrapper .ant-tag {
  margin: 0 6px;
  margin-top: 0px;
}

.tags-wrapper i {
  cursor: pointer;
  color: #34495e;
  transform: scale(1.4);
  margin: 0px 10px;
}

.tags-wrapper i:hover {
  color: #2391ff;
}

.device-cycles-info {
  width: 100%;
}

/* .device-cycles-info > label {
  font-weight: 700;
  font-size: 16px;
  color: #1f1f1f;
} */

/* .cycle-list {
  margin-bottom: 0px;
}
.cycle-list li {
  font-size: 15px;
} */

/* .cycle-list li label {
  margin-right: 12px;
  font-weight: 500;
  color: #333;
} */

.task-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 12px;
  font-size: 15px;
}

.task-wrapper label {
  margin: 0;
  font-weight: 500;
  width: 90px;
  color: #1f1f1f;
}

.task-wrapper span {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  line-height: 18px;
}

.hoverInfo span {
  margin: 4px 0;
}

.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

/* .title-wrapper .ant-rate-star {
  margin: 0;
  padding: 0;
} */

/* .title-wrapper .ant-rate-star > div {
  margin-top: -1.5em;
  margin-left: 10px;
} */

.title-wrapper .info-icon {
  position: absolute;
  right: 0;
  color: #fff;
  border-radius: 50%;
  cursor: pointer;
}

.inference-activity {
  /* margin-top: 5px; */
  /* font-weight: 500; */
}

.inference-activity > span:first-child::after {
  content: '';
  height: 14px;
  width: 1.5px;
  background: #1f1f1f;
  display: inline-block;
  margin: 0 3px 0 6px;
  margin-bottom: -1.4px;
}

.small-card span,
.small-card strong,
.middle-card span {
  font-size: 16px;
}

.small-card strong,
.middle-card strong {
  /* font-size: 28px; */
  font-weight: 500;
}

.middle-card strong {
  font-size: 28px;
}
</style>
